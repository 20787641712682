import { combineReducers } from 'redux'

import { LOG_OUT } from '../actions/actionTypes'
import { baseReducer } from './baseReducer'

const rootReducer = combineReducers({
  base: baseReducer
})

// const rootReducer = (state, action) => {
//   if (action.type === LOG_OUT) {
//     return appReducer(undefined, action)
//   }
//
//   return appReducer(state, action)
// }

export default rootReducer
