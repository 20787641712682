const JWT_TIME = process.env.REACT_APP_JWT_TIME ? +process.env.REACT_APP_JWT_TIME : 0;

class JWT {
  _accessToken;

  _refreshToken;

  _lastUpdate;

  constructor() {
    this._accessToken = localStorage.accessToken;
    this._refreshToken = localStorage.refreshToken;
    this._lastUpdate = localStorage.jwtLastUpdate ? +localStorage.jwtLastUpdate : null;
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get lastUpdate() {
    return this._lastUpdate;
  }

  getJwtData() {
    return {
      accessToken: this._accessToken,
      refreshToken: this._refreshToken,
      lastUpdate: this._lastUpdate,
    };
  }

  setJwtData(accessToken, refreshToken) {
    const jwtLastUpdate = Date.now();
    localStorage.accessToken = accessToken;
    localStorage.refreshToken = refreshToken;
    localStorage.jwtLastUpdate = jwtLastUpdate;
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    this._lastUpdate = jwtLastUpdate;
  }

  needToUpdate() {
    if (!this._lastUpdate) return true;

    return Date.now() - this._lastUpdate >= JWT_TIME - JWT_TIME * 0.3;
  }

  isExpired() {
    if (!this._lastUpdate) return true;

    return Date.now() - this._lastUpdate > JWT_TIME;
  }

  destroy() {
    delete localStorage.accessToken;
    delete localStorage.refreshToken;
    delete localStorage.jwtLastUpdate;
    this._accessToken = null;
    this._refreshToken = null;
    this._lastUpdate = null;
  }
}

export const jwt = new JWT();
