import React, {useEffect, useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import "./ModalDeposit.css";
import QRCode from "qrcode.react";
import {toEur} from "../../../utils/tools";
import {client} from "../../../services/client";
import {notification} from "../../Notification";

export const ModalDepositsQr = ({order}) => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);

  const handleCloseModal = () => {

    dispatch(setModalStates({...modalStates, modalDepositsQr: false}));
  }

  useEffect(() => {
    dispatch(setModalStates({...modalStates, modalDeposits: false}));
  }, [])

  const modalBody = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">

          <div className="deposit-form-qr">
            <div className="deposit-form-qr-image"><QRCode size={200} value={order.cryptoAddress}/></div>

            <div className="deposit-form-qr-info-container">
              <div className="deposit-form-qr-info-caption">Send {order.cryptoAmount} {order.paymentMethod}</div>
              <div>scan QR code or copy address below</div>
              <div className="deposit-form-qr-code">{order.cryptoAddress}</div>

              <div className="deposit-button-container">
                <div className="deposit-button">
                  <div className="deposit-button-copy-icon"/>
                  <div>Copy</div>
                </div>

                <div className="deposit-button">
                  <div className="deposit-button-share-icon"/>
                  <div>Share</div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="button-orange button-large"
              onClick={handleCloseModal}
            >
              Done
            </button>
          </div>

        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalDepositsQr}
      onClose={handleCloseModal}
    >
      {modalBody}
    </Modal>
  )
}