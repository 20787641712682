import React from "react";
import "./DashboardMain.css";
import {isMobile} from "../../utils/checkDevice";
import {Trade} from "./components/Trade/Trade";
import {OpenClosedOrders} from "./components/OpenClosedOrders/OpenClosedOrders";
import {OrderBook} from "./components/OrderBook/OrderBook";
import {Deposits} from "./components/Deposits/Deposits";
import {Graph} from "./components/Graph/Graph";
import {OrdersMobile} from "./components/OrdersMobile/OrdersMobile";

export const DashboardMain = () => {
  return(
    <div>
      {!isMobile() ?
        <div className="dashboard-main">
          <div className="dashboard-main-left">
            <Trade/>
            <OpenClosedOrders/>
          </div>
          <div className="dashboard-main-center">
            <Deposits/>
            <Graph/>
          </div>
          <div className="dashboard-main-right">
            <OrderBook/>
          </div>
        </div>
        :
        <OrdersMobile/>
      }
    </div>
  )
}