import React  from 'react';

import { SvgIcon } from './SvgIcon';

export const IconClose = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6002 7L0 1.3998L1.3998 0L7 5.6002L12.6002 0L14 1.3998L8.3998 7L14 12.6002L12.6002 14L7 8.3998L1.3998 14L0 12.6002L5.6002 7Z"
      fill="white"
    />
  </SvgIcon>
);
