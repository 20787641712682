import React, {useEffect, useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import "./ModalDeposit.css";
import {toEur} from "../../../utils/tools";
import {client} from "../../../services/client";
import {notification} from "../../Notification";
import {ModalDepositsQr} from "./ModalDepositsQr";

export const ModalDeposits = ({currency}) => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const rates = useSelector(store => store.base.rates);
  const [crypto, setCrypto] = useState();
  const [amountEur, setAmountEur] = useState(100);
  const [amountCrypto, setAmountCrypto] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(100);
  const [network, setNetwork] = useState();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalDeposits: false}));
  }

  const handleOpenModalDepositQr = () => {
    dispatch(setModalStates({...modalStates, modalDepositsQr: true}));
  }

  const handleChangeAmount = (e) => {
    setFixedAmount(0);
    setAmountEur(e.target.value);
  }


  const handleChangeFixedAmount = (value) => {
    setFixedAmount(value);
    setAmountEur(value);
  }

  const calcRate = () => {
    if (amountEur && rates && crypto) {
      let rate;
      let fraction;
      switch (crypto) {
        case "BTC":
          rate = rates.btc.eurRate;
          fraction = 8;
          break;
        case "ETH":
          rate = rates.eth.eurRate;
          fraction = 8;
          break;
        case "USDT":
          rate = rates.usdt.eurRate;
          fraction = 2;
          break;
        case "LMGT":
          rate = rates.lmgt.eurRate;
          fraction = 8;
          break;
      }
      setAmountCrypto((amountEur / rate).toFixed(fraction));
    }
  }

  useEffect(() => {
    calcRate();
  }, [crypto, amountEur, rates]);

  useEffect(() => {
    switch (currency) {
      case "BTC":
        setNetwork("Bitcoin");
        setCrypto(currency)
        break;
      case "ETH":
        setNetwork("Ethereum");
        setCrypto(currency)
        break;
      case "USDT":
        setNetwork("USDT with ERC-20");
        setCrypto(currency)
        break;
    }
  }, [currency]);

  const handleCreateOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const order = await client.createDepositOrder({
        type: "DEPOSIT",
        paymentMethod: crypto,
        amount: amountEur,
      });
      setOrder(order);
      handleCloseModal();
      handleOpenModalDepositQr();
    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }


  const modalBody = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <form onSubmit={handleCreateOrder} className="deposit-form">

            <div className="deposit-title">You have selected to deposit {network}.</div>

            <div className="deposit-item-container">
              <div className="deposit-input-title">EUR to deposit</div>
              <input className="deposit-input-element" type="text" value={amountEur} onChange={handleChangeAmount}/>
            </div>

            <div className="deposit-item-container">
              <div className="deposit-input-title">Or choose fixed amount</div>
              <div className="deposit-fixed-amount-container">
                <div onClick={() => handleChangeFixedAmount(50)}
                     className={fixedAmount === 50 ? "deposit-fixed-amount-item deposit-fixed-amount-item-active" : "deposit-fixed-amount-item"}>
                  {toEur(50)}
                </div>
                <div onClick={() => handleChangeFixedAmount(100)}
                     className={fixedAmount === 100 ? "deposit-fixed-amount-item deposit-fixed-amount-item-active" : "deposit-fixed-amount-item"}>
                  {toEur(100)}
                </div>
                <div onClick={() => handleChangeFixedAmount(500)}
                     className={fixedAmount === 500 ? "deposit-fixed-amount-item deposit-fixed-amount-item-active" : "deposit-fixed-amount-item"}>
                  {toEur(500)}
                </div>
              </div>
            </div>

            <div className="deposit-item-container">
              <div className="deposit-input-title">{crypto} amount</div>
              <input className="deposit-input-element" type="text" value={amountCrypto} readOnly/>
            </div>

            <div className="modal-button-container">
              {loading ? <span className="loader"/> : <button
                type="submit"
                className="button-orange button-large"
              >
                Next step
              </button>
              }
              <div className="modal-cancel-link" onClick={handleCloseModal}>Cancel</div>
            </div>
          </form>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <>
      <Modal
        open={modalStates.modalDeposits}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>

      {order && <ModalDepositsQr order={order}  />}
    </>

  )
}