export const colors = {
  backgorund: '#F5F7F9',
  black: '#000000',
  denim: '#0E76BC',
  error: '#EB001B',
  grey100: '#434C5F',
  grey20: '#D9DBDF',
  grey50: '#A1A5AF',
  pastelGreen: '#7BD45C',
  pink: '#FE839C',
  primary: '#191a36',
  seashell: '#F1F1F1',
  secondary: '#191A36',
  separation: '#FBFBFB',
  turquoise: '#38CEC6',
  white: '#FFFFFF',
  yellowOrange: '#FCB135',
  botticelli: '#C2CFE0',
  dodgerBlue: '#109cf1',
};
