import React, {useEffect, useState} from "react";
import "./TopPanel.css";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import {logout} from "../../store/actions/actions";
import {useDispatch} from "react-redux";

export const TopPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tab) => {
    setActiveTab(tab)
    if (tab === 1) {
      history.push(ROUTE.DASHBOARD_MAIN);
    } else if (tab === 2) {
      history.push(ROUTE.DASHBOARD_ACCOUNT);
    } else if (tab === 3) {
      history.push(ROUTE.DASHBOARD_PORTFOLIO);
    }
  }

  const handleActiveTab = () => {
    const path = history.location.pathname;
    switch (path) {
      case ROUTE.DASHBOARD_MAIN: {
        setActiveTab(1);
        break;
      }
      case ROUTE.DASHBOARD_ACCOUNT: {
        setActiveTab(2);
        break;
      }
      case ROUTE.DASHBOARD_PORTFOLIO: {
        setActiveTab(3);
        break;
      }
      default: {
        setActiveTab(1);
        break;
      }
    }
  }

  useEffect(() => {
    handleActiveTab();
  }, []);

  useEffect(() => {
    history.listen((location) => {
      handleActiveTab();
    })
  }, [history])

  return (
    <div className="top-panel">
      <div className="top-panel-menu">
        <div className="top-panel-item" onClick={() => handleClick(1)}>
          <div className={activeTab === 1 ? "top-panel-icon-trading-active" : "top-panel-icon-trading"}/>
          <div className={activeTab === 1 ? "top-panel-item-active" : ""}>Trading</div>
        </div>
        <div className="top-panel-item" onClick={() => handleClick(2)}>
          <div className={activeTab === 2 ? "top-panel-icon-account-active" : "top-panel-icon-account"}/>
          <div className={activeTab === 2 ? "top-panel-item-active" : ""}>Account</div>
        </div>
        <div className="top-panel-item" onClick={() => handleClick(3)}>
          <div className={activeTab === 3 ? "top-panel-icon-portfolio-active" : "top-panel-icon-portfolio"}/>
          <div className={activeTab === 3 ? "top-panel-item-active" : ""}>Portfolio</div>
        </div>
      </div>
      <div className="top-panel-right-container">
        <div className="login-bt-button" onClick={() => window.open("https://binarytorrent.com", "_blank")}>
          <div>Login BinaryTorrent</div>
        </div>
        <div className="logout-button" onClick={() => dispatch(logout())}/>
      </div>
    </div>
  )
}