import React, {useCallback, useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
} from "@mui/material";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";

export const LoginHistoryTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();

  const [headerFields, setHeaderFields] = useState([
    {name: 'ip', value: 'IP', direction: 'asc', sorted: true},
    {name: 'country', value: 'Country', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
  ]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }


  const getLoginHistory = useCallback(async () => {
    try {
      const response = await client.getLoginHistory({
        page: currentPage,
        size: rowsPerPage,
      });
      setData(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getLoginHistory();
  }, [currentPage, rowsPerPage]);


  return (
    <div>
      <Table size="small" className="login-history-table">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((item, index) => (
            <TableRow hover key={index}>
              <TableCell>{item.ip}</TableCell>
              <TableCell>{item.country}</TableCell>
              <TableCell>{item.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > 15 &&
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page',
          'size': 'small'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
          'size': 'small'
        }}
        onPageChange={onChangePage}
      />
      }
    </div>
  )
}