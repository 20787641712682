import React from "react";
import "./Portfolio.css";
import {useSelector} from "react-redux";
import {toEur} from "../../utils/tools";

export const PortfolioMobile = () => {
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(state => state.base.rates);
  const user = useSelector(state => state.base.user);

  return (
    rates && wallet && user &&
    <div className="portfolio-mobile-container">
      <div className="portfolio-mobile-header">Hello, <span className="portfolio-mobile-username">{user.username}!</span></div>

      <div className="portfolio-mobile-box">
        <div className="portfolio-mobile-box-title">Your current portfolio value</div>
        <div className="portfolio-mobile-box-balances">
          <div>{toEur(wallet.lmgtValue)}</div>
          <div className="portfolio-mobile-lmgt-balance">
            <div className="portfolio-mobile-lmgt-icon"/>
            <div>{toEur(wallet.lmgtBalance, false)}</div>
          </div>
        </div>
      </div>

      <div className="portfolio-mobile-bg"/>
    </div>
  )
}