import React, {useEffect, useCallback, useState} from 'react';
import './DashboardLayout.css'

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {setOrderBook, setRates, setUser, setWallet} from "../../../store/actions/actions";

import {notification} from "../../Notification";
import {TopPanel} from "../../TopPanel/TopPanel";
import {isMobile} from "../../../utils/checkDevice";
import {FooterMobile} from "../../Footer/FooterMobile";

export const DashboardLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(store => store.base.rates);
  const orderBook = useSelector(store => store.base.orderBook);
  const [ratesLoaded, setRatesLoaded] = useState(false);
  const [walletLoaded, setWalletLoaded] = useState(false);
  const [orderBookLoaded, setOrderBookLoaded] = useState(false);


  const fetchCurrentUser = useCallback(async () => {
    if (user === null) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  const fetchUserWallet = useCallback(async () => {
    const wallet = await client.getUserWallet();
    dispatch(setWallet(wallet));
    setWalletLoaded(true);
  }, [history]);

  const getRates = useCallback(async () => {
    try {
      const rates = await client.getRates();
      dispatch(setRates(rates));
      setRatesLoaded(true)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [history]);

  const getOrderBook = useCallback(async (type) => {
    try {
      const response = await client.getBookExchangeOrders({
        page: 0,
        size: 5,
        type: type
      });
      if (type === "BUY") {
        dispatch(setOrderBook({...orderBook, BUY: response.content}));
      } else if (type === "SELL") {
        dispatch(setOrderBook({...orderBook, SELL: response.content}));
      }
      setOrderBookLoaded(true);
    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [history]);


  useEffect(() => {
    fetchCurrentUser();
    fetchUserWallet();
    getRates();
    getOrderBook("BUY");
    getOrderBook("SELL");
  }, []);

  useEffect(() => {
    if (walletLoaded) {
      const sse = client.getStream();
      sse.onmessage = function (event) {
        if (event.data !== "") {
          const msg = JSON.parse(event.data);
          switch (msg.type) {
            case "LMGT_RATE":
              dispatch(setRates({...rates, lmgt: {eurRate: msg.value, low24h: 5.49, high24h: 5.58, change24h: 0}}));
              break;
            case "CASH_BALANCE":
              dispatch(setWallet({...wallet, cashBalance: +msg.value}));
              break;
            case "TRADING_BALANCE":
              dispatch(setWallet({...wallet, tradingBalance: +msg.value}));
              console.log(msg);
              break;
            case "FREE_TOKENS":
              dispatch(setWallet({...wallet, freeTokens: +msg.value}));
              break;
            case "ORDER_BOOK_UPDATE":
              getOrderBook("BUY");
              getOrderBook("SELL");
              break;
          }
        }
      }
    }

  }, [walletLoaded])


  return (
    <>
      <div className="dashboard-layout">
        <div className="dashboard-content">
          {!isMobile() && <TopPanel/>}
          {children}
        </div>
        {isMobile() && <FooterMobile/>}
      </div>
    </>
  )
}