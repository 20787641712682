import React, {useEffect, useState} from "react";
import "./Account.css";
import {useDispatch, useSelector} from "react-redux";
import {LoginHistoryTable} from "./components/LoginHistoryTable";
import {toEur} from "../../utils/tools";
import {isMobile} from "../../utils/checkDevice";
import {logout} from "../../store/actions/actions";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";

export const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const [limits, setLimits] = useState();

  useEffect(async () => {
    try {
      await client.getLimits().then(setLimits);
    } catch (error) {
      notification.warning(error.message);
    }
  }, [])


  return (
    <div>
      {isMobile() && <div className="account-mobile-header"/> }
      <div className="account-content">
        <div>
          <div className="account-content-title">Account information</div>
          <div className="account-form">
            <div className="account-form-item">
              <div className="account-form-icon">
                <div className="account-username-icon"/>
              </div>
              <input type="text" className="account-input" value={user?.username} readOnly/>
            </div>

            <div className="account-form-item">
              <div className="account-form-icon">
                <div className="account-phone-icon"/>
              </div>
              <input type="text" className="account-input" value={user?.phone} readOnly/>
            </div>

            <div className="account-form-item">
              <div className="account-form-icon">
                <div className="account-mail-icon"/>
              </div>
              <input type="text" className="account-input" value={user?.email} readOnly/>
            </div>

            <div className="account-form-item">
              <div className="account-form-icon">
                <div className="account-country-icon"/>
              </div>
              <input type="text" className="account-input" value={user?.countryName} readOnly/>
            </div>
          </div>

          <div>
            <div className="account-content-title">{isMobile() ? "Overview" : "Funds"}</div>
            <div className="account-funds-table">
              <div className="account-funds-row">
                <div>Cash wallet</div>
                <div>{wallet && toEur(wallet.cashBalance)}</div>
              </div>

              <div className="account-funds-row">
                <div>Trading wallet</div>
                <div>{wallet && toEur(wallet.tradingBalance)}</div>
              </div>

              <div className="account-funds-row">
                <div>Trading limits</div>
                <div>{limits && limits.amount + "/" + limits.period}</div>
              </div>

              <div className="account-funds-row">
                <div>LMGTs</div>
                <div>{wallet && toEur(wallet.freeTokens, false)}</div>
              </div>

              {isMobile() && <div className="account-logout-button" onClick={() => dispatch(logout())}>
                <div>Logout</div>
              </div>}

            </div>
          </div>
        </div>

        {!isMobile() && <div>
          <div className="account-content-title">Login history</div>
          <LoginHistoryTable/>
        </div>
        }
      </div>
    </div>
  )
}