import {
  LOG_OUT,
  SET_AUTH,
  SET_ORDER_BOOK,
  SET_RATES,
  SET_USER,
  SET_WALLET,
  SET_MODAL_STATES,
} from './actionTypes';
import {createAction} from 'redux-actions'
import {jwt} from '../../services/jwt'

const actionList = {
  logout: createAction(LOG_OUT),
  setAuth: createAction(SET_AUTH),
  setUser: createAction(SET_USER),
  setWallet: createAction(SET_WALLET),
  setRates: createAction(SET_RATES),
  setOrderBook: createAction(SET_ORDER_BOOK),
  setModalStates: createAction(SET_MODAL_STATES),
}

export const logout = () => {
  localStorage.clear()
  sessionStorage.clear();
  jwt.destroy()
  return actionList.logout()
}

export const setAuth = (isAuth) => {
  return actionList.setAuth(isAuth)
}

export const setUser = (user) => {
  return actionList.setUser(user)
}

export const setWallet = (wallet) => {
  return actionList.setWallet(wallet)
}

export const setRates = (rates) => {
  return actionList.setRates(rates)
}

export const setOrderBook = (orderBook) => {
  return actionList.setOrderBook(orderBook)
}

export const setModalStates = (modalStates) => {
  return actionList.setModalStates(modalStates);
}