import React, {useEffect, useState} from "react";
import "./Graph.css";
import {toEur} from "../../../../utils/tools";
import {useSelector} from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import {Line} from "react-chartjs-2";
import {notification} from "../../../../components/Notification";
import {client} from "../../../../services/client";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const Graph = () => {
  const rates = useSelector(state => state.base.rates);
  const [progressBarActive, setProgressBarActive] = useState(1);
  const [rateButtonActive, setRateButtonActive] = useState(1);
  const [dates, setDates] = useState([]);
  const [ratesHistory, setRatesHistory] = useState([]);
  const [dataSet, setDataSet] = useState();

  // const dates = ["1/08", "5/08", "10/08", "15/08", "20/08"];
  // const _rates = ["5.61", "5.73", "5.69", "5.76", "5.79"];

  useEffect(async () => {
    try {
      let response;

      if (rateButtonActive === 1) {
        response = await client.getRates24h();
      } else if (rateButtonActive === 2) {
        response = await client.getRates1w();
      } else if (rateButtonActive === 3) {
        response = await client.getRates1m();
      }

      let _dates = [];
      let _rates = [];
      Object.entries(response).forEach(([k, v]) => {
        _dates[k] = v.date;
        _rates[k] = v.eurRate;
      });
      setDates(_dates);
      setRatesHistory(_rates);
      setDataSet({
        labels: _dates && _dates,
        datasets: [
          {
            label: 'LMGT/EUR',
            fill: true,
            lineTension: 0,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 280);
              gradient.addColorStop(0, "rgba(83, 243, 195, 0.6)");
              gradient.addColorStop(1, "rgba(83, 243, 195, 0)");
              return gradient;
            },
            borderColor: '#1AEFAF',
            borderWidth: 1,
            data: _rates && _rates
          }
        ]
      })
    } catch (error) {
      notification.warning(error.message);
    }
  }, [rateButtonActive])

  const _dataSet = {
    labels: dates && dates,
    datasets: [
      {
        label: 'LMGT/EUR',
        fill: true,
        lineTension: 0,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 280);
          gradient.addColorStop(0, "rgba(83, 243, 195, 0.6)");
          gradient.addColorStop(1, "rgba(83, 243, 195, 0)");
          return gradient;
        },
        borderColor: '#1AEFAF',
        borderWidth: 1,
        data: ratesHistory && ratesHistory
      }
    ]
  }


  return (
    <div className="graph">
      <div className="graph-top-container">
        <div>
          <div className="graph-top-container-icons">
            <div className="graph-top-container-eur-icon"/>
            <div className="graph-top-container-lmgt-icon"/>
            <div className="graph-top-container-currencies">
              <span>EURO/</span><span className="graph-top-container-currencies-lmgt">LMGT</span>
            </div>
            <div className="graph-top-container-reload-icon"/>
          </div>

          <div className="graph-top-container-rate">{rates && rates.lmgt?.eurRate + " EURO"}</div>

          <div className="graph-top-container-rate-change">
            {rates && <>
              <div className="graph-top-container-rate-change-green">{rates.lmgt.change24h} EURO</div>
              <div>Past 24 Hours</div>
            </>}
          </div>
        </div>

        <div>
          <div className="graph-top-container-prices-header">
            <div className="graph-sort-icon" onClick={() => setProgressBarActive(progressBarActive === 1 ? 2 : 1)}/>
            <div>High / Low Price</div>
          </div>
          <div className="graph-progress-bar">
            <div
              className={progressBarActive === 1 ? "graph-progress-bar-item graph-progress-bar-item-active" : "graph-progress-bar-item"}/>
            <div
              className={progressBarActive === 2 ? "graph-progress-bar-item graph-progress-bar-item-active" : "graph-progress-bar-item"}/>
          </div>

          <div className="graph-top-container-prices">
            <div>
              <div>Low:</div>
              <div>{rates && toEur(rates.lmgt.low24h)}</div>
            </div>
            <div>
              <div>High:</div>
              <div>{rates && toEur(rates.lmgt.high24h)}</div>
            </div>
          </div>

          <div className="graph-top-rate-buttons-container">
            <div onClick={() => setRateButtonActive(1)}
                 className={rateButtonActive === 1 ? "graph-top-rate-button graph-top-rate-button-active" : "graph-top-rate-button"}>24H
            </div>
            <div onClick={() => setRateButtonActive(2)}
                 className={rateButtonActive === 2 ? "graph-top-rate-button graph-top-rate-button-active" : "graph-top-rate-button"}>1W
            </div>
            <div onClick={() => setRateButtonActive(3)}
                 className={rateButtonActive === 3 ? "graph-top-rate-button graph-top-rate-button-active" : "graph-top-rate-button"}>1M
            </div>
          </div>

        </div>
      </div>

      <div>
        <div>
          {dataSet && <Line
            data={dataSet}
            options={{
              title: {
                display: true,
                text: 'LMGT',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'left'
              }
            }}
          />
          }
        </div>
      </div>
    </div>
  )
}