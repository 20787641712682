import React from 'react';
import injectSheet from 'react-jss';

import styles from './NotificationManager.styles';
import { Notice } from './components/Notice';

export class NotificationManager extends React.Component {
  static instance;

  constructor(props) {
    super(props);
    this.state = {
      notices: [],
      some: ''
    };

    if (!NotificationManager.instance) {
      NotificationManager.instance = this;
    }
    return NotificationManager.instance;
  }

  add = (notice) => {
    this.setState(prev => ({ notices: [notice, ...prev.notices] }));
  };

  remove = (id) => {
    this.setState(prev => ({ ...prev, notices: prev.notices.filter(notice => notice.id !== id) }));
  };

  render() {
    const { classes } = this.props;
    const { notices } = this.state;
    return (
      <ul className={classes.notificationManager}>
        {notices.map(notice => (
          <Notice key={notice.id} {...notice} onClose={this.remove} />
        ))}
      </ul>
    );
  }
}

export const NotificationManagerWithStyle = injectSheet(styles)(NotificationManager);
