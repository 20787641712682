import React, {useState} from "react";
import "./ModalTrade.css";
import {Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setModalStates} from "../../../store/actions/actions";
import {useHistory} from "react-router-dom";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import {ROUTE} from "../../../constants/routes";

export const ModalTradeStart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalStates = useSelector(store => store.base.modalStates);
  const [step, setStep] = useState(1);

  const handleCloseModal = () => {
    setStep(1);
    dispatch(setModalStates({...modalStates, modalTradeStart: false}));
  }

  const handleRedirect = (type) => {
    handleCloseModal();
    history.push(ROUTE.DASHBOARD_TRADE + "?type=" + type);
  }

  const renderHeader = () => {
    if (step === 1) {
      return <div>Select token to trade</div>
    } else if (step === 2) {
      return <div>Choose sell or buy</div>
    }
  }

  const renderContent = () => {
    if (step === 1) {
      return <div className="modal-trade-mobile-items-container">
        <div className="modal-trade-mobile-circle-container" onClick={() => setStep(2)}>
          <div className="modal-trade-mobile-circle">L</div>
          <div>LMGT</div>
        </div>
      </div>
    } else if (step === 2) {
      return <div className="modal-trade-mobile-items-container">
        <div className="modal-trade-mobile-circle-container" onClick={() => handleRedirect("SELL")}>
          <div className="modal-trade-mobile-circle">
            <div className="modal-trade-sell-icon"/>
          </div>
          <div>Sell</div>
        </div>
        <div className="modal-trade-mobile-circle-container" onClick={() => handleRedirect("BUY")}>
          <div className="modal-trade-mobile-circle">
            <div className="modal-trade-buy-icon"/>
          </div>
          <div>Buy</div>
        </div>
      </div>
    }
  }

  const modalBody = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-trade-mobile-header">{renderHeader()}</div>
          <div className="modal-trade-mobile-body">
            {renderContent()}
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalTradeStart}
      onClose={handleCloseModal}
    >
      {modalBody}
    </Modal>
  )
}