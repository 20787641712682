import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {jwt} from "../../services/jwt";
import {logout, setAuth, setUser} from "../../store/actions/actions";
import {client} from "../../services/client";
import {useDispatch} from "react-redux";
import './Auth.css';
import {notification} from "../../components/Notification";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import {USER_STATUS} from "../../constants/userStatuses";
import {ROUTE} from "../../constants/routes";

export const Auth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);


  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await client.auth2fa({email, password});
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();


        if (user.status === USER_STATUS.MEMBER) {
          dispatch(setUser(user));
          dispatch(setAuth(true));
          history.push(ROUTE.DASHBOARD_MAIN);
        } else {
          notification.warning("Your account is not fully registered.")
        }

        setLoading(false);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div>
          <div className='auth-container'>
            <div>
              <div className="auth-logo"/>
              <form onSubmit={handleLogin} className="auth-form">
                <div className="form-item">
                  <input
                    id="email"
                    type="text"
                    required
                    autoComplete="off"
                    placeholder=" "
                    className="guest-input"
                  />
                  <label htmlFor="email">{dict.EX_LOGIN_FORM_EMAIL || "Email"}</label>
                </div>
                <div className="form-item">
                  <input
                    id="password"
                    type="password"
                    required
                    autoComplete="off"
                    placeholder="Password"
                    className="guest-input"
                  />
                  <label htmlFor="password">{dict.EX_LOGIN_FORM_PASSWORD || "Password"}</label>
                </div>
                <div className="form-item form-button-container">
                  {loading ? <span className="loader"/> :
                    <button type="submit" className="button-orange button-large">{dict.EX_LOGIN_FORM_BUTTON || "Login"}</button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );
}