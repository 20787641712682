import React, {useEffect, useState} from "react";
import "./FooterMobile.css"
import {useDispatch, useSelector} from "react-redux";
import {setModalStates} from "../../store/actions/actions";
import {ModalTradeStart} from "../Modals/ModalTrade/ModalTradeStart";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";

export const FooterMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalStates = useSelector(store => store.base.modalStates);
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tab) => {
    setActiveTab(tab)
    if (tab === 1) {
      history.push(ROUTE.DASHBOARD_MAIN);
    } else if (tab === 2) {
      history.push(ROUTE.DASHBOARD_PORTFOLIO);
    } else if (tab === 3) {
      history.push(ROUTE.DASHBOARD_PRICES);
    } else if (tab === 4) {
      history.push(ROUTE.DASHBOARD_ACCOUNT);
    }
  }

  const handleActiveTab = () => {
    const path = history.location.pathname;
    switch (path) {
      case ROUTE.DASHBOARD_MAIN: {
        setActiveTab(1);
        break;
      }
      case ROUTE.DASHBOARD_PORTFOLIO: {
        setActiveTab(2);
        break;
      }
      case ROUTE.DASHBOARD_PRICES: {
        setActiveTab(3);
        break;
      }
      case ROUTE.DASHBOARD_ACCOUNT: {
        setActiveTab(4);
        break;
      }
      default: {
        setActiveTab(1);
        break;
      }
    }
  }

  useEffect(() => {
    handleActiveTab();
  }, [])

  useEffect(() => {
    history.listen((location) => {
      handleActiveTab();
    })
  }, [history])

  return (
    <div className="footer-mobile">
      <div className="footer-mobile-inner">
        <div className="footer-mobile-side">
          <div className="footer-mobile-menu">
            <div
              className={activeTab === 1 ? "footer-mobile-menu-item-active footer-mobile-menu-item" : "footer-mobile-menu-item"}
              onClick={() => handleClick(1)}>
              <div
                className={activeTab === 1 ? "footer-mobile-menu-orders-icon-active" : "footer-mobile-menu-orders-icon"}/>
              <div>Orders</div>
            </div>
            <div
              className={activeTab === 2 ? "footer-mobile-menu-item-active footer-mobile-menu-item" : "footer-mobile-menu-item"}
              onClick={() => handleClick(2)}>
              <div
                className={activeTab === 2 ? "footer-mobile-menu-portfolio-icon-active" : "footer-mobile-menu-portfolio-icon"}/>
              <div>Portfolio</div>
            </div>
          </div>
        </div>

        <div className="footer-mobile-button" onClick={
          () => dispatch(setModalStates({...modalStates, modalTradeStart: true}))
        }/>

        <div className="footer-mobile-side">
          <div className="footer-mobile-menu">
            <div
              className={activeTab === 3 ? "footer-mobile-menu-item-active footer-mobile-menu-item" : "footer-mobile-menu-item"}
              onClick={() => handleClick(3)}>
              <div
                className={activeTab === 3 ? "footer-mobile-menu-prices-icon-active" : "footer-mobile-menu-prices-icon"}/>
              <div>Prices</div>
            </div>
            <div
              className={activeTab === 4 ? "footer-mobile-menu-item-active footer-mobile-menu-item" : "footer-mobile-menu-item"}
              onClick={() => handleClick(4)}>
              <div
                className={activeTab === 4 ? "footer-mobile-menu-account-icon-active" : "footer-mobile-menu-account-icon"}/>
              <div>Account</div>
            </div>
          </div>
        </div>
      </div>

      <ModalTradeStart/>

    </div>
  )
}