import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../index.css";
import "./Prices.css";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";
import {isMobile} from "../../utils/checkDevice";
import {ROUTE} from "../../constants/routes";
import {setModalStates} from "../../store/actions/actions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import {Line} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const PricesMobile = () => {
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(state => state.base.rates);
  const history = useHistory();
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const dates = ["1/02", "6/02", "10/02", "15/02", "20/02"];
  const _rates = ["5.06", "5.56", "5.76", "5.55", "5.35"];

  const dataSet = {
    labels: dates && dates,
    datasets: [
      {
        label: 'LMGT/EUR',
        fill: false,
        lineTension: 0,
        borderColor: '#FCAD2A',
        borderWidth: 3,
        data: _rates && _rates
      }
    ]
  }

  useEffect(() => {
    if (!isMobile()) {
      history.push(ROUTE.DASHBOARD_MAIN);
    }
  }, [])

  const handleRedirect = (token) => {
    history.push('/details?token=' + token);
  }

  return (
    <div className="prices-mobile-container">
      <div className="prices-mobile-title">Price LMGT</div>

      <div className="prices-mobile-box">
        <div className="prices-mobile-logo-container">
          <div className="prices-mobile-lmgt-logo"/>
          <div>LMGT</div>
        </div>
        <div className="prices-mobile-box-bottom-container">
          <div className="prices-mobile-data">
            <div className="prices-mobile-data-rate">{rates && toEur(rates.lmgt.eurRate)}</div>
            <div className="prices-mobile-data-change">{rates && rates.lmgt.change24h}</div>
          </div>

          <div className="prices-mobile-box-items-container">
            <div className="prices-mobile-box-item prices-mobile-box-item-green">
              <div>High Price</div>
              <div>{rates && toEur(rates.lmgt.high24h)}</div>
            </div>
            <div className="prices-mobile-box-item prices-mobile-box-item-red">
              <div>Low Price</div>
              <div>{rates && toEur(rates.lmgt.low24h)}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="prices-mobile-graph">
        {dataSet && <Line
          data={dataSet}
          options={{
            title: {
              display: true,
              text: 'LMGT',
              fontSize: 20
            },
            legend: {
              display: true,
              position: 'left'
            }
          }}
        />
        }
      </div>

      <button className="button-orange button-large" onClick={() => {
        dispatch(setModalStates({...modalStates, modalTradeStart: true}))
      }}>Trade Now
      </button>

      <div className="prices-mobile-about">
        <div className="prices-mobile-about-title">About LMGT</div>
        <div>
          LMGT - Lapland Minerals Gold Token is an internal token. The internal token will be swapped and listed on
          public DEX latest end of 2023. It is a BEP-20 token listed on Binance Smart Chain. Each token represents the
          value of 0.1 gram of gold collateral agreements.
        </div>
      </div>
    </div>
  )
}