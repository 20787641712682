import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {isMobile} from "../../../../utils/checkDevice";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {toEur} from "../../../../utils/tools";
import {useSelector} from "react-redux";

export const OrderBookTable = ({type}) => {
  const orderBook = useSelector(store => store.base.orderBook);
  const [orders, setOrders] = useState();
  const rates = useSelector(store => store.base.rates);

  const [headerFields, setHeaderFields] = isMobile() ? useState([
      {name: 'price', value: 'Price EUR', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Qty LMGT', direction: 'asc', sorted: true},
      {name: 'totalAmount', value: 'Total EUR', direction: 'asc', sorted: true},
    ]) :
    useState([
      {name: 'price', value: 'Price', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Qty LMGT', direction: 'asc', sorted: true},
      {name: 'totalAmount', value: 'Total EUR', direction: 'asc', sorted: true},
    ]);


  useEffect(() => {
    if (orderBook.BUY && type === "BUY") {
      setOrders(orderBook.BUY);
    }
    if (orderBook.SELL && type === "SELL") {
      setOrders(orderBook.SELL);
    }

  }, [orderBook]);


  const renderAmount = (order) => {
    return <span className={order.type === "BUY" ? "buy-amount" : "sell-amount"}>
        {toEur(order.remain, false)}
      </span>
  }

  return (
    <div>
      <Table size="small" className="oc-orders-table">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBook && rates && orders && orders.map((order, index) => (
            <TableRow hover key={index}>
              {isMobile() ?
                <>
                  <TableCell>€{toEur(rates.lmgt.eurRate, false)}</TableCell>
                  <TableCell>{renderAmount(order)}</TableCell>
                  <TableCell>€{toEur(rates.lmgt.eurRate * order.remain, false)}</TableCell>
                </> : <>
                  <TableCell>€{toEur(rates.lmgt.eurRate, false)}</TableCell>
                  <TableCell>{renderAmount(order)}</TableCell>
                  <TableCell>€{toEur(rates.lmgt.eurRate * order.remain, false)}</TableCell>
                </>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}