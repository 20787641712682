import React  from 'react';

export const SvgIcon = ({
  fill = 'inherit',
  stroke = 'inherit',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  children,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    stroke={stroke}
    fill={fill}
    {...rest}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);
