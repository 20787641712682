import React, {useState} from "react";
import "./OrdersMobile.css";
import {OrderBook} from "../OrderBook/OrderBook";
import {OrdersTable} from "../OpenClosedOrders/OrdersTable";

export const OrdersMobile = () => {
  const [activeTab, setActiveTab] = useState(1);

  const renderContent = () => {
    if (activeTab === 1) {
      return <OrderBook/>
    } else if (activeTab === 2) {
      return <div className="oc-orders"><OrdersTable tab={1}/></div>
    } else if (activeTab === 3) {
      return <div className="oc-orders"><OrdersTable tab={2}/></div>
    }
  }

return (
  <div className="orders-mobile">
    <div className="orders-mobile-header">
      <div className="orders-mobile-header-icon"/>
    </div>
    <div className="orders-mobile-header-text">
      <div className="orders-mobile-header-text-title">LMGT Orders</div>
      <div>Lapland Minerals Gold Token (internal)</div>
    </div>

    <div className="orders-mobile-menu">
      <div
        className={activeTab === 1 ? "orders-mobile-menu-item orders-mobile-menu-item-active" : "orders-mobile-menu-item"}
        onClick={() => setActiveTab(1)}>
        Order Book
      </div>
      <div
        className={activeTab === 2 ? "orders-mobile-menu-item orders-mobile-menu-item-active" : "orders-mobile-menu-item"}
        onClick={() => setActiveTab(2)}>
        Open
      </div>
      <div
        className={activeTab === 3 ? "orders-mobile-menu-item orders-mobile-menu-item-active" : "orders-mobile-menu-item"}
        onClick={() => setActiveTab(3)}>
        Closed
      </div>
    </div>

    <div>{renderContent()}</div>
  </div>
)
}