import { colors } from '../../../../../../styles/colors';
import { fonts, fontFamily } from '../../../../../../styles/fonts';
import { shadow } from '../../../../../../styles/shadows';

export default {
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 14,
  },
  fadeIn: {
    animation: '$fadeIn .2s linear',
  },
  notice: {
    ...fonts.h3,
    borderRadius: 3,
    boxShadow: shadow.main,
    color: colors.white,
    display: 'block',
    fontFamily,
    marginBottom: 10,
    padding: [11, 50, 11, 26],
    position: 'relative',
    zIndex: 1000,

    '&.success': {
      // backgroundColor: colors.pastelGreen,
      backgroundColor: '#9ec116',
    },

    '&.info': {
      backgroundColor: colors.yellowOrange,
    },

    '&.warning': {
      backgroundColor: colors.pink,
    },
  },

  fadeOut: {
    opacity: 0,
    overdlow: 'hidden',
    transition: '.5s',
  },

  '@keyframes fadeIn': {
    from: {
      opacity: 0,
      padding: [0, 50, 0, 26],
    },
    to: {
      opacity: 1,
      padding: [11, 50, 11, 26],
    },
  },
};
