export default {
  notificationManager: {
    left: '50%',
    listStyleType: 'none',
    margin: 0,
    minWidth: 300,
    padding: 0,
    position: 'fixed',
    top: 20,
    transform: 'translateX(-50%)',
    zIndex: 10000,
  },
};
