import { logout } from '../store/actions/actions'
import { api } from './api'
import { client, Client } from './client'
import { jwt } from './jwt'
import {API_URLS} from "./apiUrls";

class Interceptor {
  injectStore = (store) => {
    api.httpClient.interceptors.response.use(undefined, (error) => {
      const status = error.response.status;
      const config = error.config
      if (status === 401 && config && !config.__isRetryRequest && jwt.accessToken) {
        client
          .refreshToken()
          .then(response => {
            jwt.setJwtData(response.access_token, response.refresh_token)
            error.config.__isRetryRequest = true
            error.config.headers.token = response.access_token
            error.config.data = error.config.data ? JSON.parse(error.config.data) : {}
            api.httpClient(error.config)
          })
          .catch(() => {
            store.dispatch(logout())
          })
      }

      return Promise.reject(error)
    })

    api.httpClient.interceptors.request.use(
      (config) => {
        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json;charset=UTF-8';
          config.headers.Accept = 'application/json';
        }

        if (
          jwt.lastUpdate &&
          jwt.needToUpdate() &&
          config.url &&
          !config.url.includes(API_URLS.OAUTH.TOKEN)
        ) {
          client.refreshToken().then(response => {
            jwt.setJwtData(response.access_token, response.refresh_token);
          });
        }
        if (jwt.accessToken && config.url && !config.url.includes(API_URLS.OAUTH.TOKEN)) {
          config.headers.Authorization = `Bearer ${jwt.accessToken}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  };
}

const interceptor = new Interceptor()

export default interceptor
