import { v1 as uuid } from 'uuid';

import { NotificationManager } from './components/NotificationManager';

export class NotificationApi {
  get notificationManagerInstance() {
    return new NotificationManager();
  }

  success = (content, config) => {
    this.notificationManagerInstance.add({
      content,
      id: uuid(),
      type: 'success',
      ...config,
    });
  };

  info = (content, config) => {
    this.notificationManagerInstance.add({
      content,
      id: uuid(),
      type: 'info',
      ...config,
    });
  };

  warning = (content, config) => {
    this.notificationManagerInstance.add({
      content,
      id: uuid(),
      type: 'warning',
      ...config,
    });
  };
}

export const notification = new NotificationApi();
