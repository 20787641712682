export const ROUTE = {
  ROOT: "/",

  LOGIN: "/login",
  PASSWORD_RESET: "/password-reset",

  DASHBOARD_MAIN: "/",
  DASHBOARD_PORTFOLIO: "/portfolio",
  DASHBOARD_TRADE: "/trade",
  DASHBOARD_DEPOSITS: "/deposits",
  DASHBOARD_PRICES: "/prices",
  DASHBOARD_ACCOUNT: "/account",
}
