import React, {useCallback, useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import "./OpenClosedOrders.css"
import {isMobile} from "../../../../utils/checkDevice";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {toEur} from "../../../../utils/tools";
import {useSelector} from "react-redux";

export const OrdersTable = ({tab}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState();
  const rates = useSelector(store => store.base.rates);

  const [headerFields, setHeaderFields] = isMobile() ? useState([
    {name: 'marketSize', value: 'Type/size', direction: 'asc', sorted: true},
    {name: 'time', value: 'Time', direction: 'asc', sorted: true},
    {name: 'eur', value: 'EUR', direction: 'asc', sorted: true},
  ]) : tab === 1 ? useState([
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ]) : useState([
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ])



  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }


  const getOrders = (async () => {
    try {
      let response;
      if (tab === 1) {
        response = await client.getOpenExchangeOrders({
          page: currentPage,
          size: rowsPerPage,
        });
      } else {
        response = await client.getClosedExchangeOrders({
          page: currentPage,
          size: rowsPerPage,
        });
      }
      setOrders(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  });

  useEffect(() => {
    getOrders();
  }, [tab, currentPage, rowsPerPage]);

  useEffect(() => {
    if (!isMobile()) {
      if (tab === 1) {
        setHeaderFields([
          {name: 'date', value: 'Date', direction: 'asc', sorted: true},
          {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
          {name: 'type', value: 'Type', direction: 'asc', sorted: true},
          {name: 'price', value: 'Price', direction: 'asc', sorted: true},
          {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
          {name: 'actions', value: '', direction: 'asc', sorted: true},
        ])
      } else if (tab === 2) {
        setHeaderFields([
          {name: 'date', value: 'Date', direction: 'asc', sorted: true},
          {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
          {name: 'type', value: 'Type', direction: 'asc', sorted: true},
          {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
          {name: 'actions', value: '', direction: 'asc', sorted: true},
        ])
      }
    }
  }, [tab])


  const renderAmount = (order) => {
    return <span className={order.type === "BUY" ? "buy-amount" : "sell-amount"}>
        {tab === 1 ? toEur(order.remain, false) : toEur(order.amount, false)}
      </span>
  }

  const renderActions = (order) => {
    if (order.status.toUpperCase() === "ACTIVE") {
      return (
        <Tooltip title="Cancel" aria-label="cancel" followCursor>
          <div className="cancel-button" onClick={() => cancelOrder(order.id)}/>
        </Tooltip>
      )
    }
  }

  const cancelOrder = async (id) => {
    try {
      await client.cancelExchangeOrder(id);
      notification.success("Order " + id + " canceled");
      getOrders();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  return (
    <div>
      <Table size="small" className="oc-orders-table">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rates && orders && orders.map((order, index) => (
            <TableRow hover key={index}>
              {isMobile() ?
                <>
                  <TableCell>{renderAmount(order)}</TableCell>
                  <TableCell>{order.createdAt}</TableCell>
                  <TableCell>€{tab === 1 ? toEur(rates.lmgt.eurRate * order.remain, false) : toEur(order.price, false)}</TableCell>
                  {tab === 1 && <TableCell>{renderActions(order)}</TableCell>}
                </> : <>
                  <TableCell>{order.createdAt}</TableCell>
                  <TableCell>{renderAmount(order)}</TableCell>
                  <TableCell>{order.type}</TableCell>
                  {tab === 1 && <TableCell>€{toEur(rates.lmgt.eurRate, false)}</TableCell>}
                  <TableCell>€{tab === 1 ? toEur(rates.lmgt.eurRate * order.remain, false) : toEur(order.price, false)}</TableCell>
                  {tab === 1 && <TableCell>{renderActions(order)}</TableCell>}
                </>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > 5 &&
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page',
          'size': 'small'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
          'size': 'small'
        }}
        onPageChange={onChangePage}
      />
      }
    </div>
  )
}