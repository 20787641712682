import { rem } from './mixins';

export const fontFamily = "'Open Sans', 'Arial', 'sans-serif'";

export const fonts = {
  h1: {
    fontSize: rem(30),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(41),
  },
  h2: {
    fontSize: rem(24),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(33),
  },
  h3: {
    fontSize: rem(14),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(19),
  },
  h4: {
    fontSize: rem(16),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(22),
    textTransform: 'uppercase',
  },
  h5: {
    fontSize: rem(12),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(16),
  },
  h6: {
    fontSize: rem(10),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(14),
    textTransform: 'uppercase',
  },
  h7: {
    fontSize: rem(10),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(16),
    textTransform: 'uppercase',
  },
  h8: {
    fontSize: rem(12),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(18),
  },
  h9: {
    fontSize: rem(14),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(18),
  },
  h10: {
    fontSize: rem(16),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(18),
  },
  h11: {
    fontSize: rem(20),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(27),
  },
  h12: {
    fontSize: rem(18),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(25),
  },
  small: {
    fontSize: rem(10),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: rem(14),
  },
};
