import React from 'react';
import {connect} from 'react-redux';
import {client} from "../../services/client";


export const LanguageContext = React.createContext({
  lang: '',
  dict: {},
  onChange: (localKey) => {
  },
});

export const LanguageConsumer = LanguageContext.Consumer;

const mapStateToProps = (state) => ({});

class LanguageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getLocale();
  }

  componentDidMount() {
    const locale = this.getLocale();
    const {lang, dict} = locale;
    this.setState({lang, dict}, () => {
      this.handleChangeLanguage(lang);
    });
  }

  getLocale = () => {
    if (localStorage.locale) {
      const locale = JSON.parse(localStorage.locale);
      const {lang, dict} = locale;
      return {lang, dict};
    } else {
      return {lang: 'GB', dict: {}};
    }
  };

  handleChangeLanguage = async (localeKey) => {
    const response = await this.fetchLocale(localeKey);
    if (response.status !== 404) {
      this.setLocale({lang: localeKey, dict: response[localeKey]});
    } else if (response.status === 404 || response.type) {
      const response = await this.fetchLocale('GB');
      this.setLocale({lang: 'GB', dict: response.data['GB']});
    }
  };

  fetchLocale = async (localeKey) => await client.getLocale(localeKey);

  setLocale = (locale) => {
    this.setState({lang: locale.lang, dict: locale.dict}, () => {
      localStorage.setItem('locale', JSON.stringify({lang: locale.lang, dict: locale.dict}));
    });
  }

  render() {
    return (
      <LanguageContext.Provider value={{
        lang: this.state.lang,
        dict: this.state.dict,
        onChange: this.handleChangeLanguage
      }}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export default connect (mapStateToProps)(LanguageProvider);
