import React, {useState} from "react";
import "./OpenClosedOrders.css";
import {OrdersTable} from "./OrdersTable";


export const OpenClosedOrders = () => {
  const [activeTab, setActiveTab] = useState(1);


  const changeTab = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div className="oc-orders">
      <div className="oc-orders-tabs">
        <div className={activeTab === 1? "oc-orders-tabs-item oc-orders-tabs-item-active" : "oc-orders-tabs-item"}
             onClick={() => changeTab(1)}>Open orders
        </div>
        <div className={activeTab === 2 ? "oc-orders-tabs-item oc-orders-tabs-item-active" : "oc-orders-tabs-item"}
             onClick={() => changeTab(2)}>Closed orders
        </div>
      </div>
      <div>
        {activeTab && <OrdersTable tab={activeTab}/>}
      </div>
    </div>
  )
}