import React, {useEffect, useState} from "react";
import "./Deposits.css";
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {ModalDeposits} from "../../../../components/Modals/ModalDeposits/ModalDeposits";
import {setModalStates} from "../../../../store/actions/actions";
import {isMobile} from "../../../../utils/checkDevice";

export const Deposits = () => {
  const dispatch = useDispatch();
  const rates = useSelector(store => store.base.rates);
  const modalStates = useSelector(store => store.base.modalDeposits);
  const [currency, setCurrency] = useState();


  const handleOpenModal = (currency) => {
    setCurrency(currency);
  }

  useEffect(() => {
    if (currency) {
      dispatch(setModalStates({...modalStates, modalDeposits: true}));
      setCurrency(null)
    }
  }, [currency])

  return (
    <>
      {isMobile() && <div className="deposits-mobile-title">Deposit Funds</div>}
      <div className="deposits">
        <div className="deposits-item" onClick={() => handleOpenModal("BTC")}>
          <div className="crypto-icon btc-icon"/>
          <div className="deposits-item-center">
            <div className="deposits-item-title">BTC</div>
            <div className="deposits-item-description">Deposit funds using Bitcoin from any wallet.</div>
          </div>
          <div className="deposits-arrow-icon"/>
        </div>
        <div className="deposits-item" onClick={() => handleOpenModal("ETH")}>
          <div className="crypto-icon eth-icon"/>
          <div className="deposits-item-center">
            <div className="deposits-item-title">ETH</div>
            <div className="deposits-item-description">Transfer funds using Ethereum from any wallet.</div>
          </div>
          <div className="deposits-arrow-icon"/>
        </div>
        <div className="deposits-item" onClick={() => handleOpenModal("USDT")}>
          <div className="crypto-icon usdt-icon"/>
          <div className="deposits-item-center">
            <div className="deposits-item-title">USDT</div>
            <div className="deposits-item-description">Transfer funds using Tether with ERC-20</div>
          </div>
          <div className="deposits-arrow-icon"/>
        </div>

        <ModalDeposits currency={currency}/>

      </div>
    </>
  )
}