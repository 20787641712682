import React from 'react';
import './GuestLayout.css'

export const GuestLayout = ({children}) => {
  return (
    <>
      <div className="guest-body">
        <div className="guest-content">{children}</div>
      </div>
    </>
  )
}