import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {DashboardLayout} from "./components/Layout/DashboardLayout/DashboardLayout";
import {Auth} from "./pages/auth/Auth";
import {GuestLayout} from "./components/Layout/GuestLayout/GuestLayout";
import {DashboardMain} from "./pages/main/DashboardMain";
import {ROUTE} from "./constants/routes";
import {TradeMobile} from "./pages/main/components/Trade/TradeMobile";
import {Deposits} from "./pages/main/components/Deposits/Deposits";
import {PricesMobile} from "./pages/prices/PricesMobile";
import {Portfolio} from "./pages/portfolio/Portfolio";
import {Account} from "./pages/account/Account";

export const useRoutes = (isAuth, userStatus) => {
  if (isAuth) {
    return (
      <DashboardLayout>
        <Route path={ROUTE.DASHBOARD_MAIN} exact>
          <DashboardMain/>
        </Route>
        <Route path={ROUTE.DASHBOARD_TRADE} exact>
          <TradeMobile/>
        </Route>
        <Route path={ROUTE.DASHBOARD_DEPOSITS} exact>
          <Deposits/>
        </Route>
        <Route path={ROUTE.DASHBOARD_PRICES} exact>
          <PricesMobile/>
        </Route>
        <Route path={ROUTE.DASHBOARD_PORTFOLIO} exact>
          <Portfolio/>
        </Route>
        <Route path={ROUTE.DASHBOARD_ACCOUNT} exact>
          <Account/>
        </Route>

      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path="/login">
          <Auth/>
        </Route>
        <Redirect to={'/login'}/>
      </Switch>
    </GuestLayout>
  )
}