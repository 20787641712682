import React from "react";
import "./OrderBook.css";
import {OrderBookTable} from "./OrderBookTable";
import {useSelector} from "react-redux";
import {toEur} from "../../../../utils/tools";
import {isMobile} from "../../../../utils/checkDevice";

export const OrderBook = () => {
  const rates = useSelector(state => state.base.rates);

  return(
    <div className="order-book">
      {!isMobile() && <div className="order-book-title">Order Book</div>}
      <OrderBookTable type="BUY"/>

      <div className="trading-price">
        <div>Trading Price {rates && toEur(rates?.lmgt?.eurRate)}</div>
      </div>

      <OrderBookTable type="SELL"/>
    </div>
  )
}