import React from "react";
import "./Portfolio.css";
import {useSelector} from "react-redux";
import {toEur} from "../../utils/tools";
import {isMobile} from "../../utils/checkDevice";
import {PortfolioMobile} from "./PortfolioMobile";

export const Portfolio = () => {
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(state => state.base.rates);

  return (
    !isMobile() ?
      <div className="portfolio-top-panel">
        {rates && wallet &&
        <>
          <div>Current Internal Portfolio Value - {toEur(wallet.lmgtValue)}
            <span className="portfolio-top-panel-grey-label"> ({toEur(wallet.lmgtBalance, false)} LMGTs)</span>
          </div>
          <div>Gold 0.1 gram @ {toEur(rates.lmgt.eurRate)}</div>
        </>
        }
      </div>
      :
      <PortfolioMobile/>
  )
}