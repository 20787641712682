import {handleActions} from 'redux-actions'
import {
  LOG_OUT,
  SET_AUTH,
  SET_USER,
  SET_WALLET,
  SET_RATES,
  SET_ORDER_BOOK,
  SET_MODAL_STATES
} from '../actions/actionTypes'

const initialState = {
  isAuth: false,
  user: null,
  wallet: null,
  rates: null,
  orderBook: {
    "BUY" : null,
    "SELL": null,
  },
  modalStates: {
    "modalDeposits": false,
    "modalDepositsQr": false,
    "modalTradeStart": false,
  },
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
    SET_WALLET: (state, {payload}) => ({
      ...state,
      wallet: payload,
    }),
    SET_RATES: (state, {payload}) => ({
      ...state,
      rates: payload,
    }),
    SET_ORDER_BOOK: (state, {payload}) => ({
      ...state,
      orderBook: payload,
    }),
    SET_MODAL_STATES: (state, {payload}) => ({
      ...state,
      modalStates: payload,
    }),
  },
  initialState,
)
