import React, {createRef, useEffect, useState} from "react";
import "./Trade.css";
import {useSelector} from "react-redux";
import {toEur} from "../../../../utils/tools";
import {notification} from "../../../../components/Notification";
import {client} from "../../../../services/client";

export const Trade = () => {
  const rates = useSelector(state => state.base.rates);
  const wallet = useSelector(state => state.base.wallet);
  const [activeTab, setActiveTab] = useState(1);
  const [fee, setFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [qty, setQty] = useState();
  const qtyRef = createRef();
  const amountRef = createRef();
  const [loading, setLoading] = useState(false);


  const calc = (price) => {
    let fee;
    let totalPrice;
    if (activeTab && activeTab === 1) {
      fee = 0;
      totalPrice = price;
    } else {
      fee = price * 0.1;
      totalPrice = price - fee;
    }
    setPrice(price);
    setFee(fee);
    setTotalPrice(totalPrice);
  }

  const handleChangeQty = (e) => {
    let qty = e.target.value;
    if (isNaN(qty) || qty === "") {
      setPrice(0);
      setFee(0);
      setQty("");
      return;
    }
    qty = parseInt(qty);
    setQty(qty);
    const price = rates.lmgt.eurRate * qty;
    calc(price);
  }

  const handleChangeTab = (value) => {
    setActiveTab(value);
  }

  const resetForm = () => {
    setQty("");
    setFee(0);
    setPrice(0);
    setTotalPrice(0);
  }

  useEffect(() => {
    calc(price);
  }, [activeTab])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await client.createExchangeOrder({
        amount: qty,
        price: price,
        type: activeTab === 1 ? "BUY" : "SELL",
      });
      notification.success("Your order is submitted");
      resetForm();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
    setLoading(false)
  }

  return (
    <div className="trade">
      <div className="trade-title">Trade</div>

      <div className="trade-tabs">
        <div className={activeTab === 1 ? "trade-tabs-active-tab" : ""} onClick={() => handleChangeTab(1)}>
          <div>Buy LMGT</div>
        </div>
        <div className={activeTab === 2 ? "trade-tabs-active-tab" : ""} onClick={() => handleChangeTab(2)}>
          <div>Sell LMGT</div>
        </div>
      </div>

      <div className="trade-wallet">
        {wallet && <div>
          <div className="trade-wallet-item">
            <div className="trade-wallet-icon"/>
            <div>{wallet.tradingBalance && toEur(wallet.tradingBalance)} (Trading)</div>
          </div>
          <div className="trade-wallet-item">
            <div className="trade-lmgt-icon"/>
            <div>{wallet.freeTokens && toEur(wallet.freeTokens, false)} (LMGT)</div>
          </div>
          <div className="trade-wallet-item">
            <div className="trade-wallet-icon"/>
            <div>{wallet.cashBalance && toEur(wallet.cashBalance)} (Cash)</div>
          </div>
        </div>
        }
      </div>


      <form className="trade-form" onSubmit={handleSubmit}>
        <div className="trade-input" onClick={() => {
          qtyRef.current.focus()
        }}>
          <div className="trade-input-side">
            <div className="trade-input-title">Quantity</div>
            <input className="trade-input-element" type="text" ref={qtyRef} value={qty} onChange={handleChangeQty} required min={1}/>
          </div>
          <div className="trade-input-side">
            <div className="trade-input-data-container">
              <div className="trade-input-lmgt-icon"/>
              <div>LMGT</div>
            </div>
          </div>
        </div>

        <div className="trade-input" onClick={() => {
          amountRef.current.focus()
        }}>
          <div className="trade-input-side">
            <div className="trade-input-title">{activeTab === 1 ? "Buy" : "Sell"} For (EUR)</div>
            <input className="trade-input-element" type="text" ref={amountRef} value={price} readOnly/>
          </div>
          <div className="trade-input-side">
            <div className="trade-input-data-container">
              <div className="trade-input-eur-icon"/>
              <div>EUR</div>
            </div>
          </div>
        </div>

        <div className="trading-total">Total: (+Fee {toEur(fee, false)}) {toEur(totalPrice)}</div>

        <div className="trade-button-container">
          {loading ? <span className="loader"/> :
            activeTab === 1 ?
              <button type="submit" className="button-blue trade-button">
                Buy for {toEur(totalPrice)}
              </button>
              :
              <button type="submit" className="button-red trade-button">
                Sell for {toEur(totalPrice)}
              </button>
          }
        </div>

      </form>

    </div>
  )
}