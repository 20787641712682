import React, {createRef, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../../../components/Notification";
import {ROUTE} from "../../../../constants/routes";
import "./TradeMobile.css";
import {useSelector} from "react-redux";
import {toEur} from "../../../../utils/tools";
import {client} from "../../../../services/client";
import {ModalDeposits} from "../../../../components/Modals/ModalDeposits/ModalDeposits";

export const TradeMobile = () => {
  const history = useHistory();
  const [type, setType] = useState();
  const rates = useSelector(state => state.base.rates);
  const [fee, setFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [qty, setQty] = useState();
  const qtyRef = createRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const type = new URLSearchParams(history.location.search).get('type');
    if (!type && type !== "SELL" && type !== "BUY") {
      notification.warning("Wrong path");
      history.push(ROUTE.DASHBOARD_MAIN);
    } else {
      setType(type);
      qtyRef.current.focus()
    }
  }, [history]);

  const calc = (price) => {
    let fee;
    let totalPrice;
    if (type && type === "BUY") {
      fee = 0;
      totalPrice = price;
    } else {
      fee = price * 0.1;
      totalPrice = price - fee;
    }
    setPrice(price);
    setFee(fee);
    setTotalPrice(totalPrice);
  }

  const handleChangeQty = (e) => {
    let qty = e.target.value;
    if (isNaN(qty) || qty === "") {
      setPrice(0);
      setFee(0);
      setQty(null);
      return;
    }
    qty = parseInt(qty);
    setQty(qty);
    const price = rates.lmgt.eurRate * qty;
    calc(price);
  }

  const resetForm = () => {
    setQty(null);
    setFee(0);
    setPrice(0);
    setTotalPrice(0);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await client.createExchangeOrder({
        amount: qty,
        price: price,
        type: type,
      });
      notification.success("Your order is submitted");
      resetForm();
      history.push(ROUTE.DASHBOARD_MAIN)
    } catch (e) {
      notification.warning(e.message || e.error);
    }
    setLoading(false)
  }

  return (
    <div className="trade-mobile-container">
      <div className="trade-mobile-title">{type && type.charAt(0) + type.substring(1).toLowerCase()} LMGT</div>

      <div>
        <div className="trade-mobile-input-box-right">
          <div className="trade-mobile-input-box-left">
            <div className="trade-mobile-input-inner-box-left">
              <div className="trade-mobile-input-label">LMGT Amount</div>
              <input type="text" className="trade-mobile-input" ref={qtyRef} onChange={handleChangeQty}/>
            </div>
          </div>
          <div className="trade-mobile-input-inner-box-right">
            <div className="trade-mobile-input-label">Token price</div>
            <input type="text" className="trade-mobile-input" value={"€" + price} readOnly/>
          </div>
        </div>
      </div>

      <div className="trade-mobile-table">
        <div className="trade-mobile-table-row">
          <div className="trade-mobile-table-cell">{qty > 0 ? qty + " x " + rates.lmgt.eurRate : "0"}</div>
          <div className="trade-mobile-table-cell">{qty > 0 ? toEur(price) : toEur(0)}</div>
        </div>
        <div className="trade-mobile-table-row">
          <div className="trade-mobile-table-cell">{type === "SELL" ? "Selling Fee 5%" : "Fee 0%"}</div>
          <div className="trade-mobile-table-cell">{toEur(fee)}</div>
        </div>
        <div className="trade-mobile-table-row">
          <div
            className="trade-mobile-table-cell trade-mobile-table-cell-bold">{type === "SELL" ? "Total receive" : "Total cost"}</div>
          <div className="trade-mobile-table-cell">{toEur(totalPrice)}</div>
        </div>
      </div>

      <div className="trade-mobile-deposit-button">
        <div className="trade-mobile-deposit-button-add">+</div>
        <div className="trade-mobile-deposit-button-label" onClick={() => {
          history.push(ROUTE.DASHBOARD_DEPOSITS)
        }}>Deposit Funds
        </div>
        <div className="trade-mobile-deposit-button-next"/>
      </div>

      {loading ? <span className="loader"/> :
        <button onClick={handleSubmit}
                className={type === "BUY" ? "button-green button-large" : "button-red button-large"}>
          {type && type.charAt(0) + type.substring(1).toLowerCase()} Now
        </button>
      }

      <ModalDeposits/>

    </div>
  )
}